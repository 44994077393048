import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Constants } from './constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {


  constructor(private readonly http: HttpClient) { }

  private baseUrl: string = Constants.baseUrl  //"http://192.168.1.10:8080/api/";

  private tenantbaseUrl: String = Constants.tenantBaseUrl

  private apiOAuthEndpoint: any = Constants.apiOAuthEndpoint

  private CLIENT_ID: string = Constants.CLIENT_ID;
  private CLIENT_SECRET: string = Constants.CLIENT_SECRET;

  private HTTPS : string = Constants.https

  private tenantName: string = localStorage.getItem(Constants.tenantName); //Constants.tenantName; //${localStorage.getItem(Constants.tenantName)};

  LocalBaseUrl() {
    this.baseUrl = Constants.baseUrl_local
    this.apiOAuthEndpoint = Constants.apiOAuthEndpoint_local
    this.CLIENT_ID = Constants.CLIENT_ID_local;
    this.CLIENT_SECRET = Constants.CLIENT_SECRET_local;
    this.HTTPS = Constants.https_local;
  }

  getresponse(method: string, endpoint: string, content_type: string | string[], postParams: any): Observable<any> {

    let headers = new HttpHeaders();
    headers = headers.append("Authorization", `Bearer ${localStorage.getItem(Constants.ACCESS_TOKEN)}`);
    headers = headers.append("Content-Type", content_type);

    if(localStorage.getItem(Constants.Server)=="local"){
      this.HTTPS = Constants.https_local;
    }else{
      this.HTTPS = Constants.https;
    }

    let url = this.HTTPS + `${localStorage.getItem(Constants.tenantName)}` + `${this.tenantbaseUrl}` + endpoint
    let response = this.http.request(method, url, { body: postParams, headers: headers, withCredentials: true });
    return response;

  }

  downloadFile(endpoint: string) {
    let headers = new HttpHeaders();
    headers = headers.append("Authorization", `Bearer ${localStorage.getItem(Constants.ACCESS_TOKEN)}`);
    headers = headers.append("Content-Type", 'text/csv');

    if(localStorage.getItem(Constants.Server)=="local"){
      this.HTTPS = Constants.https_local;
    }else{
      this.HTTPS = Constants.https;
    }
    let url = this.HTTPS + `${localStorage.getItem(Constants.tenantName)}` + `${this.tenantbaseUrl}` + endpoint
    return this.http.request('GET', url, {responseType: 'blob', headers: headers, withCredentials: true });
  }

  getUser(method: string, endpoint: string, content_type: string | string[], postParams: any): Observable<any> {

    let headers = new HttpHeaders();
    headers = headers.append("Authorization", `Bearer ${localStorage.getItem(Constants.ACCESS_TOKEN)}`);
    headers = headers.append("Content-Type", content_type);

    let url = `${this.baseUrl}` + endpoint
    let response = this.http.request(method, url, { body: postParams, headers: headers, withCredentials: true });
    return response;

  }

  Oauth2Login(username: any, password: any) {
    let headers = new HttpHeaders();
    //headers = headers.append("Authorization",  `Basic ${Constants.B64fortset}`);
    headers = headers.append("Authorization", `Basic ${this.getOAuth2base64()}`);
    headers = headers.append("Content-Type", Constants.CONTENT_TYPES.APPLICATION_FORM);
    headers = headers.append(Constants.CONTENT_TYPES.X_SKIP_INTERCEPTOR, "");
    let postParams = `grant_type=password&username=${username}&password=${password}`

    localStorage.setItem(Constants.tenantName, username);
    let apiOAuthEndpoint = this.apiOAuthEndpoint
    return this.http.post(apiOAuthEndpoint, postParams, { headers: headers, withCredentials: true });
  }

  Oauth2RefreshToken() {
    let headers = new HttpHeaders();
    headers = headers.append("Authorization", `Basic ${this.getOAuth2base64()}`);
    headers = headers.append("Content-Type", Constants.CONTENT_TYPES.APPLICATION_FORM);
    headers = headers.append(Constants.CONTENT_TYPES.X_SKIP_INTERCEPTOR, "");

    let postParams = `grant_type=refresh_token&refresh_token=${localStorage.getItem(Constants.REFRESH_TOKEN)}`
    return this.http.post(Constants.apiOAuthEndpoint, postParams, { headers: headers, withCredentials: true });
  }

  getOAuth2base64() {
    return btoa(this.CLIENT_ID + ":" + this.CLIENT_SECRET);
  }

  getDateInfo(): Observable<any> {
    return this.http.request("GET", `${this.tenantbaseUrl}dateinfo/`);
  }

}
