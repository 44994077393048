export const Constants = {
    production: false,
    apiUrl: 'http://localhost:4000',


    tenantBaseUrl: '/api/',

    tenantName: 'tenantName',

    // --------------local server-----------------

    https_local: 'http://',
    apiOAuthEndpoint_local: 'http://vcl.mtechlanka.com:8055/o/token/',
    baseUrl_local: 'http://vcl.mtechlanka.com:8055/',
    CLIENT_SECRET_local: "EOuGmIDitQSX9ui6c6egNI5vqnq1zv2nf1rSiyVgda6fQj7eQTZYrxWiYJ2TeyrbLxDXiQgoYx1SyvlzCCu2zRdb6IxJ9MtzK7dsiF7JMaTYg2KblIOnVQD5hB5pI9SR",
    CLIENT_ID_local: "1tjsIJX2qKfeP1D6j6fJu70J12TEbEUqzG0igGGc",

    // -------------- end local server-----------------

    
    // -------------- live server ------------------

    https: 'https://',
    baseUrl: 'https://public.visitchecklist.com/',
    apiOAuthEndpoint: 'https://public.visitchecklist.com/o/token/',
    CLIENT_SECRET: "EOuGmIDitQSX9ui6c6egNI5vqnq1zv2nf1rSiyVgda6fQj7eQTZYrxWiYJ2TeyrbLxDXiQgoYx1SyvlzCCu2zRdb6IxJ9MtzK7dsiF7JMaTYg2KblIOnVQD5hB5pI9SR",
    CLIENT_ID: "1tjsIJX2qKfeP1D6j6fJu70J12TEbEUqzG0igGGc",

    //------------- end live server ------------------



    webClientId: "1061674504137-0teq8r3fmkhplt4f72ud4inuheim9fvb.apps.googleusercontent.com",

    ACCESS_TOKEN: "LOGIN_ACCESS_TOKEN",
    REFRESH_TOKEN: "LOGIN_REFRESH_TOKEN",

    REMEMBER_ME: "REMEMBER_ME",

    B64fortset: "ckpvYVFvMVFVaVhia2MxTnQzSmVOeVhMUkc1TEtjSGtvVDR5T0cyVDp6YXMwRlNrVUxZUEwyak1KcmJJNUpFS2lwTU9Fd0dJajRDdkZ6akJrN2VxMFFtZVV3Z2EwbmVxTVFNSzQ4WXppT3pnZEpzVVJvWGJMc3hnWUdkYTc1SnU0cGJNdUMxMXRZQVVOa2Rwb1hzeGJrZktVMFhRNlpOUWxBV1p1QkxSMA==",

    COMMON_LOADING: "COMMON_LOADING",

    USERNAME: 'USERNAME',
    POSITION: 'POSITION',
    IMGURL: 'IMAGEURL',
    schema_name: 'schema_name',

    MODEL_TITLE_CREATE: "Create",
    MODEL_TITLE_UPDATE: "Update",


    Server:"server",

    //request methods list 
    METHODS: {
        GET: "GET",
        POST: "POST",
        PUT: "PUT",
        DELETE: "DELETE",
        OPTIONS: "OPTIONS",
    },

    //request content Types 
    CONTENT_TYPES: {
        APPLICATION_FORM: "application/x-www-form-urlencoded",
        APPLICATION_JSON: "application/json",
        X_SKIP_INTERCEPTOR: "X-Skip-Interceptor",
        TEXT_CSV: "text/csv",
    },

    PAGE: {
        LIMIT: 20,
    },

}
