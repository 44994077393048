import { throwError as observableThrowError, Observable, BehaviorSubject } from 'rxjs';

import { take, filter, catchError, switchMap, finalize } from 'rxjs/operators';
import { Injectable, Injector } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpSentEvent, HttpHeaderResponse, HttpProgressEvent, HttpResponse, HttpUserEvent, HttpErrorResponse } from "@angular/common/http";
import { ApiService } from './api.service';
import { Constants } from './constants';

@Injectable()
export class RequestInterceptorService implements HttpInterceptor {

    isRefreshingToken: boolean = false;
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    tokens: any;

    constructor(private injector: Injector) { }

    addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
        return req.clone({ setHeaders: { Authorization: 'Bearer ' + token } })
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
        if (req.headers.has(Constants.CONTENT_TYPES.X_SKIP_INTERCEPTOR)) {
            const headers = req.headers.delete(Constants.CONTENT_TYPES.X_SKIP_INTERCEPTOR);
            return next.handle(req.clone({ headers }));
        } else {
            return next.handle(req).pipe(
                catchError(error => {
                    if (error instanceof HttpErrorResponse) {
                        switch ((<HttpErrorResponse>error).status) {
                            case 400:
                                return this.handle400Error(error);
                            case 401:
                                return this.handle401Error(req, next);
                            default:
                                return observableThrowError(error);
                        }
                    } else {
                        return observableThrowError(error);
                    }
                }));
        }
    }

    handle400Error(error) {
        if (error && error.status === 400 && error.error && error.error.error === 'invalid_grant') {
            // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
            return this.logoutUser();
        }

        return observableThrowError(error);
    }

    handle401Error(req: HttpRequest<any>, next: HttpHandler) {

        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;

            // Reset here so that the following requests wait until the token
            // comes back from the refreshToken call.
            this.tokenSubject.next(null);

            const authService = this.injector.get(ApiService);

            return authService.Oauth2RefreshToken().pipe(
                switchMap((newToken: string) => {
                    console.log("-------newToken---", newToken);
                    this.tokens =  newToken;
                    if (this.tokens) {
                        this.tokenSubject.next(this.tokens.access_token);
                        localStorage.setItem(Constants.ACCESS_TOKEN, this.tokens.access_token);
                        localStorage.setItem(Constants.REFRESH_TOKEN, this.tokens.refresh_token);
                        return next.handle(this.addToken(req, this.tokens.access_token));
                    }
                    // If we don't get a new token, we are in trouble so logout.
                    return this.logoutUser();
                }),
                catchError(error => {
                    // If there is an exception calling 'refreshToken', bad news so logout.
                    return this.logoutUser();
                }),
                finalize(() => {
                    this.isRefreshingToken = false;
                }));

        } else {
            return this.tokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(token => {
                    return next.handle(req);
                }));
        }
    }

    logoutUser() {
        // Route to the login page (implementation up to you)
        return observableThrowError("");
    }

}