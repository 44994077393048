import { Component, OnInit, NgModule } from '@angular/core';
import { FormGroup, NgModel, FormBuilder, Validators } from '@angular/forms';
import { Constants } from '../constants';
import { ApiService } from '../api.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
@NgModule({
  imports: [
  ]
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  tokens: any;
  preventDuplicates = false;
  index = 1;
  destroyByClick = true;
  duration = 2000;
  hasIcon = true;
  isStartProgress: boolean = false;
  remember_me: string = 'false';
  constructor(private formBuilder: FormBuilder, private ApiProvider: ApiService, private toastrService: ToastrService, private router: Router, ) {
    // if (localStorage.getItem(Constants.ACCESS_TOKEN) != 'null' && localStorage.getItem(Constants.ACCESS_TOKEN) != null && 
    // localStorage.getItem(Constants.tenantName) != null) {
    if (localStorage.getItem(Constants.REMEMBER_ME) == 'true') {
      sessionStorage.setItem("is_reloaded", "true")
    }
    if (sessionStorage.getItem("is_reloaded") && localStorage.getItem(Constants.REMEMBER_ME) == 'true' && localStorage.getItem(Constants.ACCESS_TOKEN) != 'null' && localStorage.getItem(Constants.ACCESS_TOKEN) != null &&
      localStorage.getItem(Constants.tenantName) != null) {
      this.router.navigateByUrl('users');
    }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.loginForm.invalid) {
      return;
    }
    this.isStartProgress = true;
    this.loading = true;

    var username = this.f.username.value
    var password = this.f.password.value

    var lastChar = username[username.length - 1];
    if (lastChar == "#") {
      this.ApiProvider.LocalBaseUrl()
      localStorage.setItem(Constants.Server, "local")
      username = username.substring(0, username.length - 1);
    } else {
      localStorage.setItem(Constants.Server, "live")
    }

    this.ApiProvider.Oauth2Login(username, password).subscribe(data => {
      this.tokens = data;

      localStorage.setItem(Constants.ACCESS_TOKEN, this.tokens.access_token);
      localStorage.setItem(Constants.REFRESH_TOKEN, this.tokens.refresh_token);
      localStorage.setItem(Constants.REMEMBER_ME, this.remember_me);
      this.getUserData();
      this.loading = false;
    }, error => {
      this.isStartProgress = false
      this.loading = false;
      this.Warning(error.error.error_description, 'error!');

    });
  }
  getUserData() {
    this.ApiProvider.getUser(Constants.METHODS.GET, "api/client/specifictenant/", Constants.CONTENT_TYPES.APPLICATION_JSON, {}).subscribe(data => {

      if (localStorage.getItem(Constants.Server) == "local"){
        localStorage.setItem(Constants.tenantName, data[0].domain_url+":8055");
        localStorage.setItem(Constants.IMGURL, Constants.https_local+localStorage.getItem(Constants.tenantName)+data[0].tenant_logo)
      }else{
        localStorage.setItem(Constants.tenantName, data[0].domain_url);
        localStorage.setItem(Constants.IMGURL, data[0].tenant_logo)
      }
      localStorage.setItem(Constants.USERNAME, data[0].tenant_name)
      localStorage.setItem(Constants.schema_name, data[0].schema_name)
      this.isStartProgress = true
      this.goToHome();
    }, error => {
      // alert("-------eer---");
      this.isStartProgress = false;
      this.Warning(error.error.error, "Please try again");

    });
  }

  rememberMe(e) {
    this.remember_me = e.target.checked
  }
  goToHome() {
    // this.router.navigate(['/home']);
    sessionStorage.setItem("is_reloaded", 'true');
    this.router.navigate(['users']);
  }

  Warning(message, title) {
    const type = ['', 'info', 'success', 'warning', 'danger'];

    const color = 4;

    $.notify({
      message: message,
      title: title

    }, {
        type: type[color],
        timer: 2000,
        placement: {
          from: 'top',
          align: 'right'
        },
        template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon"></i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          '</div>' +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          '</div>'
      });
  }

  goToAdmin() {
    var adminUrl = "https://public.visitchecklist.com/admin/password_reset/"
    window.location.href = adminUrl
  }
}
